// Extensions Jquery

function parseFilterString(filterString) {

    // sample filter: "(Auditor~startswith~'Gabe'~and~Auditor~endswith~'Newell')~and~(Company~contains~'Valve'~and~Company~neq~'EA')";

    // Remove all of the ' characters from the string.
    filterString = filterString.replace(/[']/g, '');

    // Split the string into an array of strings, using the ~ as a delimiter.
    var ss = filterString.split("~"); // ss stands for "split string". I'm clever.

    var F = []; // Used to store all of the parsed filters.
    var fIndex = -1; // Used to track filter index.
    var cIndex = 0; // Used to track filter index within a composite filter object.
    var isComposite = false; // Used to indicate if a composite filter is currently being parsed.

    for (var i = 0; i < ss.length; i++) {
        if (i % 4 == 0) { // Field.
            if (ss[i].indexOf('(') > -1) { // If we're starting a composite object, create a composite object and add it to the parsed filters.
                F.push({
                    filters: [],
                    logic: ""
                });
                fIndex++; // We added an object to the array, so increment the counter.
                F[fIndex]
                F[fIndex].filters.push({
                    field: ss[i].replace('(', ''),
                    operator: "",
                    value: ""
                });
                cIndex = 0; // We added the first filter to the composite object, so set the counter.
                isComposite = true;
            }
            else if (isComposite) { // If we're parsing the second filter in a composite filter object, then add the field to the child filter.
                F[fIndex].filters.push({
                    field: ss[i],
                    operator: "",
                    value: ""
                });
                cIndex++; // We added the second filter to the composite object, so increment the counter.
            }
            else { // Add the field as normal.
                F.push({
                    field: ss[i],
                    operator: "",
                    value: ""
                });
                fIndex++; // We added an object to the array, so increment the counter.
            }
        }
        if (i % 4 == 1) { // Operator.
            if (isComposite) {
                F[fIndex].filters[cIndex].operator = ss[i];
            }
            else {
                F[fIndex].operator = ss[i];
            }
        }
        if (i % 4 == 2) { // Value.
            if (ss[i].indexOf(')') > -1) {
                F[fIndex].filters[cIndex].value = ss[i].replace(')', '');
                isComposite = false;
            }
            else if (isComposite) {
                F[fIndex].filters[cIndex].value = ss[i];
            }
            else {
                F[fIndex].value = ss[i];
            }
        }
        if (i % 4 == 3) { // Logic.
            if (isComposite) {
                F[fIndex].logic = ss[i]; // Add the logic to the composite filter object.
            }
            // If the filter is not composite, the logic will always be "and". So, we just don't do anything if that's the case.
        }
    }

    return {
        filters: F,
        logic: "and"
    };
};

(function ($) {

    $.fn.scale = function (x) {
        if (!$(this).filter(':visible').length && x != 1) return $(this);
        if (!$(this).parent().hasClass('scaleContainer')) {
            $(this).wrap($('<div class="scaleContainer">').css('position', 'relative'));
            $(this).data({
                'originalWidth': $(this).width(),
                'originalHeight': $(this).height()
            });
        }
        $(this).css({
            'transform': 'scale(' + x + ')',
            '-ms-transform': 'scale(' + x + ')',
            '-moz-transform': 'scale(' + x + ')',
            '-webkit-transform': 'scale(' + x + ')',
            'transform-origin': 'right bottom',
            '-ms-transform-origin': 'right bottom',
            '-moz-transform-origin': 'right bottom',
            '-webkit-transform-origin': 'right bottom',
            'position': 'absolute',
            'bottom': '0',
            'right': '0',
        });
        if (x == 1)
            $(this).unwrap().css('position', 'static'); else
            $(this).parent()
                .width($(this).data('originalWidth') * x)
                .height($(this).data('originalHeight') * x);
        return $(this);
    };

    var delay = (function () {
        var timer = 0;
        return function (callback, ms) {
            clearTimeout(timer);
            timer = setTimeout(callback, ms);
        };
    })();

    checkMinimum = function (value) {
        return (value.length == 0 || value.length >= 2);
    }

    var lastStringSearch = [];

    $.fn.stringSearch = function (idGrid, component, bindLater) {
        //Permet de gérer différent type de composant, par défaut le KendoGrid
        if (typeof component === 'undefined') { component = 'kendoGrid'; }
        if (typeof idGrid === 'undefined') { idGrid = 'Grid'; }

        var searchInput = $(this);
        //searchInput.focus();
        searchInput.addClass('searchi');

        searchInput.keyup(function () {
            var item = this;
            delay(function () {
                applyStringFilter(idGrid, item, false, true, component, bindLater);
            }, 700);
        });

        searchInput.bind('paste', function () {
            applyStringFilter(idGrid, item, false, true, component, bindLater);
        });
    }

    //$.fn.stringSearch = function () {
    //    var searchInput = $(this);
    //    searchInput.focus();
    //    searchInput.addClass('searchi');
    //    searchInput.keyup(function () {
    //        var item = this;
    //        if (checkMinimum(item.value) && item.value != lastStringSearch[searchInput.id]) {
    //            delay(function () {
    //                lastStringSearch[searchInput.id] = item.value;
    //                applyStringFilter(item);
    //            }, 700);
    //        }
    //    });
    //    searchInput.bind('paste', function () {
    //        if (checkMinimum(this.value))
    //            applyStringFilter(this);
    //    });
    //}

    $.fn.splitStringSearch = function (idGrid, component, bindLater, filterAnd) {
        //Permet de gérer différent type de composant, par défaut le KendoGrid
        if (typeof component === 'undefined') { component = 'kendoGrid'; }
        if (typeof idGrid === 'undefined') { idGrid = 'Grid'; }

        var searchInput = $(this);
        //searchInput.focus();
        searchInput.addClass('searchi');
        searchInput.keyup(function () {
            applySplitStringFilter(idGrid, this, false, true, component, bindLater, filterAnd);
        });
    }

    $.fn.stringSearchFormatted = function (idGrid) {
        var searchInput = $(this);
        //searchInput.focus();
        searchInput.addClass('searchi');
        searchInput.keyup(function () {
            applyStringFilter(idGrid, this);
        });
    }


    $.fn.stringSearchSch = function () {
        var searchInput = $(this);
        //searchInput.focus();
        searchInput.addClass('searchi');
        searchInput.keyup(function () {
            applyStringFilterSch(this);
        });
    }

    $.fn.stringSearchSchRes = function () {
        var searchInput = $(this);
        //searchInput.focus();
        searchInput.addClass('searchi');
        searchInput.keyup(function () {
            applyStringFilterSch(this, false);
            applyStringFilterSchRes(this);
        });
    }

    $.fn.numericSearch = function () {
        var searchInput = $(this);
        //searchInput.focus();
        searchInput.addClass('searchi');
        searchInput.change(function () {
            applyNumericFilter(this);
        });
    }

    $.fn.dateSearch = function (filterType) {
        var searchInput = $(this);
        var gridId = $(this).parents("[data-grid]:first").data("grid");
        //searchInput.focus();

        searchInput.addClass('searchi');
        searchInput.keyup(function () {
            if (this.value == '' || isDate(this.value))
                applyDateFilter(this, filterType, gridId);
        });
    }

    $.fn.checkSearch = function (GridId, component, bindLater) {

        //Permet de gérer différent type de composant, par défaut le KendoGrid
        if (typeof component === 'undefined') { component = 'kendoGrid'; }
        if (typeof GridId === 'undefined') { GridId = 'Grid'; }
        var searchInput = $(this);
        //searchInput.focus();
        searchInput.addClass('searchi');
        if ($(this).is(':checked')) {
            //delay(function () {
            saveFilter(searchInput.attr('data-search'), "check", searchInput.is(':checked'), searchInput.val(), true);

            //}, 1000);
            //var currentFilters = [];

            //currentFilters.push({ field: $(this).attr('data-search'), operator: 'eq', value: $(this).val() });
            //currentFiltersPerGrid[GridId] = currentFilters;
        }
        searchInput.click(function () {
            applyCheckFilter(this, GridId, component, bindLater, false);
        });
    }

    $.fn.checkSearchUnique = function (GridId, component, bindLater) {
        //Permet de gérer différent type de composant, par défaut le KendoGrid
        if (typeof component === 'undefined') { component = 'kendoGrid'; }
        if (typeof GridId === 'undefined') { GridId = 'Grid'; }

        var searchInput = $(this);
        //searchInput.focus();
        searchInput.addClass('searchi');
        searchInput.click(function () {
            var searchCriteria = $(this).data('search');
            if ($(this).is(':checked')) {
                applyFilter(searchCriteria, true, 'eq', 'and', false, GridId, component, bindLater);
            } else {
                applyFilter(searchCriteria, false, 'eq', 'and', false, GridId, component, bindLater);
            }
        });
    }

    $.fn.selectSearch = function (GridId, component, bindLater) {

        if (typeof (GridId) === "undefined")
            GridId = "Grid";

        if ($(this).val() != '') {
            saveFilter($(this).attr("data-search"), "kendoDropDownList", $(this).val(), '', true);
        }

        $(this).change(function () {
            applyDropDownListFilter(this, null, bindLater, GridId);
        });
    }

    $.fn.selectSearchSch = function () {
        $(this).change(function () {
            applyDropDownListFilterSch(this);
        });
    }

    $.fn.selectSearchId = function (bindLater, idGrid) {
        $(this).change(function () {
            applyDropDownListFilterId(this, null, bindLater, idGrid);
        });
    }

    $.fn.selectSearchIdSch = function () {
        $(this).change(function () {
            applyDropDownListFilterIdSch(this);
        });
    }

    $.fn.selectMulti = function (bindLater, idGrid) {
        $(this).change(function () {
            applyMultiFilterId(this, null, bindLater, idGrid);
        });
    }
    $.fn.selectMultiSch = function () {
        $(this).change(function () {
            applyMultiFilterIdSch(this);
        });
    }
    $.fn.selectMultiSchRes = function () {
        $(this).change(function () {
            applyMultiFilterIdRes(this);
        });
    }
})(jQuery);

var subfilter = { logic: 'or', filters: [] };

var currentFiltersPerGrid = [];
var currentFilters = [];


saveFilter = function (fieldId, fieldType, value, dataSupplementaire, init) {
    var filters = getFilters();
    
    if (init && filters[fieldId + dataSupplementaire] != undefined)
        return;
    
    filters[fieldId + dataSupplementaire] = { fieldType: fieldType, value: value, dataSupplementaire: dataSupplementaire, fieldId: fieldId };
    var url = window.location.pathname.replace("/", "_");
    setCookie(url, JSON.stringify(filters), 60);
};

getFilters = function () {
    // chargement du cookie de recherche
    var url = window.location.pathname.replace("/", "_");
    var cookie = getCookie(url);
    if (cookie == "") return {};
    return JSON.parse(cookie);
}
clearFilters = function () {
    var url = window.location.pathname.replace("/", "_");
    setCookie(url, JSON.stringify({}), 60);
}
reloadFilters = function () {
    var filters = getFilters();

    for (var field in filters) {
        var item = $("[data-search='" + filters[field].fieldId + "']");

        if (filters[field].fieldType == "check") {
            item.each(function () {
                $(this).removeAttr('checked');
            });
        }

        if (filters[field].fieldType == "kendoDropDownList") {
            item.each(function () {
                $(this).val('');
            });
        }
    }

    for (var field in filters) {
        var item = $("[data-search='" + filters[field].fieldId + "']");
        
        switch (filters[field].fieldType) {
            case "kendoDropDownList":
                item.data(filters[field].fieldType).value(filters[field].value);
                item.val(filters[field].value);
                applyDropDownListFilter(item, true, true);
                break;
            case "kendoDropDownList_id":
                item.data("kendoDropDownList").value(filters[field].value);
                applyDropDownListFilterId(item, true, true);
                break;
            case "kendoDropDownList_sch":
                item.data("kendoDropDownList").value(filters[field].value);
                applyDropDownListFilterSch(item, true);
                break;
            case "kendoDropDownList_id_sch":
                item.data("kendoDropDownList").value(filters[field].value);
                applyDropDownListFilterIdSch(item, true);
                break;


            case "kendoMultiSelect":
            case "kendoMultiSelect_sch":
                item.data("kendoMultiSelect").value(filters[field].value);
                if (filters[field].fieldType == "kendoMultiSelect_sch") applyMultiFilterIdSch(item, true);
                else applyMultiFilterId(item, true, true);
                break;
            case "check":
                item.each(function () {
                    if (filters[field].dataSupplementaire == $(this).val()) {
                        $(this).prop("checked", filters[field].value);
                        applyCheckFilter($(this), null, null, true, true);
                    }
                });
                break;
            default:
                item.val(filters[field].value).keyup();
        }
    }

    updateGrid();
}

function setCookie(cname, cvalue, exminutes) {
    var d = new Date();
    d.setTime(d.getTime() + (exminutes * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    var content = cname + "=" + escape(cvalue) + "; " + expires;
    document.cookie = content;
}
function getCookie(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1);
        if (c.indexOf(name) == 0) return unescape(c.substring(name.length, c.length));
    }
    return "";
}

clearFilter = function (filterFields) {
    var kendoData = $('#Grid').data('kendoGrid');
    var currFilterObj = kendoData.dataSource.filter();
    var currentFilters = currFilterObj ? currFilterObj.filters : [];

    filterFields = "," + filterFields + ",";
    var tabToSuppr = new Array();
    if (currentFilters && currentFilters.length > 0) {
        for (var i = 0; i < currentFilters.length; i++) {
            if (filterFields.indexOf("," + currentFilters[i].field + ",") >= 0) {
                tabToSuppr.push(i);
            }
        }
    }
    for (var i = tabToSuppr.length - 1; i >= 0 ; i--) {
        currentFilters.splice(tabToSuppr[i], 1);
    }
    kendoData.dataSource.filter({ filters: currentFilters });
}


function dateSearchCompare(id, idCompare) {
    var val = $("#" + id).val();
    var valCompare = $("#" + idCompare).val();

    if (val != "" && valCompare != "") {
        var partsVal = val.split("/");
        var dVal = new Date(Number(partsVal[2]), Number(partsVal[1]) - 1, Number(partsVal[0]));
        var partsValCompare = valCompare.split("/");
        var dValCompare = new Date(Number(partsValCompare[2]), Number(partsValCompare[1]) - 1, Number(partsValCompare[0]));

        if (dVal > dValCompare)
            $("#" + idCompare).val(val);
    }

}


updateGrid = function (idGrid) {

    if (typeof idGrid === 'undefined' || !idGrid) { idGrid = 'Grid'; }

    if (!currentFiltersPerGrid[idGrid])
        currentFiltersPerGrid[idGrid] = [];
    else if (currentFiltersPerGrid[idGrid].length > 0) {
        var currentFilters = currentFiltersPerGrid[idGrid];

        if ($("#" + idGrid).data("kendoGrid") && currentFilters)
            kendoData = $("#" + idGrid).data("kendoGrid").dataSource.filter({ logic: 'and', filters: currentFilters });
    }
}

applyFilter = function (filterField, filterValue, filterOperator, logic, isSchedule, idGrid, accentSensitive, component, bindLater, forceZero) {

    //Permet de gérer différent type de composant, par défaut le KendoGrid
    if (typeof component === 'undefined' || !component || component == "") { component = 'kendoGrid'; }
    if (typeof idGrid === 'undefined' || !idGrid || idGrid == "") { idGrid = 'Grid'; }

    var kendoData = null;

    if (!isSchedule) {
        kendoData = $('#' + idGrid).data(component);
    } else {
        idGrid = "mainScheduler";
        kendoData = $('#mainScheduler').data('kendoScheduler');
    }
    //var currFilterObj = kendoData.dataSource.filter();
    //if (!currentFiltersPerGrid[idGrid]) currentFiltersPerGrid[idGrid] = [];
    //var currentFilters = currentFiltersPerGrid[idGrid];

    var currFilterObj = kendoData.dataSource.filter();
    var currentFilters;
    currentFilters = currFilterObj ? currFilterObj.filters : [];

    if (currentFilters && currentFilters.length > 0) {
        for (var i = 0; i < currentFilters.length; i++) {
            if (currentFilters[i].field == filterField && currentFilters[i].operator == filterOperator) {
                currentFilters.splice(i, 1);
                break;
            }
        }
    }

    i++;
    var format = '';
    if (filterField.indexOf('date') > 1)
        format = '{0:dd/MM/yyyy}';

    if ((filterValue != '' && filterValue) || forceZero) {
        if (accentSensitive === undefined || !accentSensitive)
            filterValue = filterValue;
        else
            filterValue = convertirChaineSansAccent(filterValue);

        currentFilters.push({ field: filterField, operator: filterOperator, value: filterValue });
    }
    // si on n'a pas spécifié que le filtre ne rafraichit pas immédiatement la liste, on l'
    if (!bindLater)
        kendoData.dataSource.filter({ logic: logic, filters: currentFilters });


    $("#isFilter").val("0");
    currentFiltersPerGrid[idGrid] = currentFilters;

    //if (isSchedule) {
    //    var dataSource = kendoData.resources[0].dataSource;

    //    dataSource.one("change", function () {
    //        kendoData.view(kendoData.view().name);
    //    });
    //    dataSource.read();
    //}
}



applyFilterRes = function (filterField, filterValue, filterOperator, logic, isSchedule) {

    var kendoData = null;
    var schData = $('#mainScheduler').data('kendoScheduler');

    kendoData = schData.resources[1];
    var currFilterObj = schData.resources[1].dataSource.filter();
    var currentFilters = currFilterObj ? currFilterObj.filters : [];

    if (currentFilters && currentFilters.length > 0) {
        for (var i = 0; i < currentFilters.length; i++) {
            if (currentFilters[i].field == filterField) {
                currentFilters.splice(i, 1);
                break;
            }
        }
    }
    i++;

    if (filterValue != '0' && filterValue) {

        filterValue = convertirChaineSansAccent(filterValue);

        currentFilters.push({ field: filterField, operator: filterOperator, value: filterValue });
    }


    // partie planning
    //$("#isFilter").val("0");

    var dataSource = kendoData.dataSource;


    var allData = dataSource.data();
    var query = new kendo.data.Query(allData);
    var filteredData = query.filter(currentFilters).data;
    if (!jQuery.isEmptyObject(filteredData)) {

        dataSource.one("change", function () {
            schData.view(schData.view().name);
        });

        dataSource.filter({ logic: logic, filters: currentFilters });
        //dataSource.read();
    }
}


toggleSubFilter2 = function (filterField, idGrid, component) {

    //Permet de gérer différent type de composant, par défaut le KendoGrid
    if (typeof component === 'undefined' || !component) { component = 'kendoGrid'; }
    if (typeof idGrid === 'undefined' || !idGrid) { idGrid = 'Grid'; }

    var gridData = $('#' + idGrid).data(component);

    var currFilterObj = gridData.dataSource.filter();
    var currentFilters;
    currentFilters = currFilterObj ? currFilterObj.filters : [];

    //if (subfilter && subfilter.filters.length > 0 && $.inArray(subfilter, currentFilters) == -1)
    //    currentFilters.push(subfilter);
    //else {
    $.each(currentFilters, function (index, item) {
        if (item !== undefined) {
            if (item.filters !== undefined // Le filtre est "subfilter" ET
                && (item.filters.length == 0 // Le filtre ne contiens plus rien, on le retire OU
                    || (item.filters.length > 0  // Le filtre ne contiens la valeur, on le retire
                        && item.filters[0].field == filterField)))
                currentFilters.splice(index, 1);
        }
    });
    // Replace
    if (subfilter && subfilter.filters.length > 0)
        currentFilters.push(subfilter);
    //}
    gridData.dataSource.filter({ logic: 'and', filters: currentFilters });
}



toggleSubFilterSchedule = function (filterField) {
    var schData = $('#mainScheduler').data('kendoScheduler');
    var currFilterObj = schData.dataSource.filter();
    var currentFilters;

    currentFilters = currFilterObj ? currFilterObj.filters : [];

    //if (subfilter && subfilter.filters.length > 0 && $.inArray(subfilter, currentFilters) == -1)
    //    currentFilters.push(subfilter);
    //else {
    $.each(currentFilters, function (index, item) {
        if (item != null && item.filters !== undefined // Le filtre est "subfilter" ET
            && (item.filters.length == 0 // Le filtre ne contiens plus rien, on le retire OU
                || (item.filters.length > 0  // Le filtre ne contiens la valeur, on le retire
                    && item.filters[0].field == filterField)))
            currentFilters.splice(index, 1);
    });
    // Replace
    if (subfilter && subfilter.filters.length > 0)
        currentFilters.push(subfilter);
    //}
    schData.dataSource.filter({ logic: 'and', filters: currentFilters });
    //$("#isFilter").val("0");
    var dataSource = schData.resources[0].dataSource;

    dataSource.one("change", function () {
        schData.view(schData.view().name);
    });
    //dataSource.read();
}


toggleSubFilterScheduleRes = function (filterField) {
    var schData = $('#mainScheduler').data('kendoScheduler');
    var currFilterObj = schData.resources[1].dataSource.filter();
    var currentFilters;

    currentFilters = currFilterObj ? currFilterObj.filters : [];

    //if (subfilter && subfilter.filters.length > 0 && $.inArray(subfilter, currentFilters) == -1)
    //    currentFilters.push(subfilter);
    //else {
    $.each(currentFilters, function (index, item) {
        if (item != null && item.filters !== undefined // Le filtre est "subfilter" ET
            && (item.filters.length == 0 // Le filtre ne contiens plus rien, on le retire OU
                || (item.filters.length > 0  // Le filtre ne contiens la valeur, on le retire
                    && item.filters[0].field == filterField)))
            currentFilters.splice(index, 1);
    });
    // Replace
    if (subfilter && subfilter.filters.length > 0)
        currentFilters.push(subfilter);
    //}
    //$("#isFilter").val("0");
    var dataSource = schData.resources[1].dataSource;


    var allData = dataSource.data();
    var query = new kendo.data.Query(allData);
    var filteredData = query.filter(currentFilters).data;

    if (!jQuery.isEmptyObject(filteredData)) {
        dataSource.one("change", function () {
            schData.view(schData.view().name);
        });

        dataSource.filter({ logic: 'and', filters: currentFilters });

    }
    //dataSource.read();
}


toggleSubFilter = function (filterField, idGrid, component, bindLater) {

    //Permet de gérer différent type de composant, par défaut le KendoGrid
    if (typeof component === 'undefined' || !component) { component = 'kendoGrid'; }
    if (typeof idGrid === 'undefined' || !idGrid) { idGrid = 'Grid'; }

    var gridData = $('#' + idGrid).data(component);

    var currFilterObj = gridData.dataSource.filter();

    var currentFilters;
    currentFilters = currFilterObj ? currFilterObj.filters : [];

    if (subfilter && subfilter.filters.length > 0 && $.inArray(subfilter, currentFilters) == -1)
        currentFilters.push(subfilter);
    else {
        $.each(currentFilters, function (index, item) {
            if (item !== undefined) {
                if (item.filters !== undefined // Le filtre est "subfilter" ET
                    && (item.filters.length == 0 // Le filtre ne contiens plus rien, on le retire OU
                        || (item.filters.length > 0  // Le filtre ne contiens la valeur, on le retire
                            && item.filters[0].field == filterField)))
                    currentFilters.splice(index, 1);
            }
        });
        // Replace
        if (subfilter && subfilter.filters.length > 0)
            currentFilters.push(subfilter);
    }

    gridData.dataSource.filter({ logic: 'and', filters: currentFilters });

}

var normalize = (function () {
    var a = ['À', 'Á', 'Â', 'Ã', 'Ä', 'Å', 'Æ', 'Ç', 'È', 'É', 'Ê', 'Ë', 'Ì', 'Í', 'Î', 'Ï', 'Ð', 'Ñ', 'Ò', 'Ó', 'Ô', 'Õ', 'Ö', 'Ø', 'Ù', 'Ú', 'Û', 'Ü', 'Ý', 'ß', 'à', 'á', 'â', 'ã', 'ä', 'å', 'æ', 'ç', 'è', 'é', 'ê', 'ë', 'ì', 'í', 'î', 'ï', 'ñ', 'ò', 'ó', 'ô', 'õ', 'ö', 'ø', 'ù', 'ú', 'û', 'ü', 'ý', 'ÿ', 'Ā', 'ā', 'Ă', 'ă', 'Ą', 'ą', 'Ć', 'ć', 'Ĉ', 'ĉ', 'Ċ', 'ċ', 'Č', 'č', 'Ď', 'ď', 'Đ', 'đ', 'Ē', 'ē', 'Ĕ', 'ĕ', 'Ė', 'ė', 'Ę', 'ę', 'Ě', 'ě', 'Ĝ', 'ĝ', 'Ğ', 'ğ', 'Ġ', 'ġ', 'Ģ', 'ģ', 'Ĥ', 'ĥ', 'Ħ', 'ħ', 'Ĩ', 'ĩ', 'Ī', 'ī', 'Ĭ', 'ĭ', 'Į', 'į', 'İ', 'ı', 'Ĳ', 'ĳ', 'Ĵ', 'ĵ', 'Ķ', 'ķ', 'Ĺ', 'ĺ', 'Ļ', 'ļ', 'Ľ', 'ľ', 'Ŀ', 'ŀ', 'Ł', 'ł', 'Ń', 'ń', 'Ņ', 'ņ', 'Ň', 'ň', 'ŉ', 'Ō', 'ō', 'Ŏ', 'ŏ', 'Ő', 'ő', 'Œ', 'œ', 'Ŕ', 'ŕ', 'Ŗ', 'ŗ', 'Ř', 'ř', 'Ś', 'ś', 'Ŝ', 'ŝ', 'Ş', 'ş', 'Š', 'š', 'Ţ', 'ţ', 'Ť', 'ť', 'Ŧ', 'ŧ', 'Ũ', 'ũ', 'Ū', 'ū', 'Ŭ', 'ŭ', 'Ů', 'ů', 'Ű', 'ű', 'Ų', 'ų', 'Ŵ', 'ŵ', 'Ŷ', 'ŷ', 'Ÿ', 'Ź', 'ź', 'Ż', 'ż', 'Ž', 'ž', 'ſ', 'ƒ', 'Ơ', 'ơ', 'Ư', 'ư', 'Ǎ', 'ǎ', 'Ǐ', 'ǐ', 'Ǒ', 'ǒ', 'Ǔ', 'ǔ', 'Ǖ', 'ǖ', 'Ǘ', 'ǘ', 'Ǚ', 'ǚ', 'Ǜ', 'ǜ', 'Ǻ', 'ǻ', 'Ǽ', 'ǽ', 'Ǿ', 'ǿ'];
    var b = ['A', 'A', 'A', 'A', 'A', 'A', 'AE', 'C', 'E', 'E', 'E', 'E', 'I', 'I', 'I', 'I', 'D', 'N', 'O', 'O', 'O', 'O', 'O', 'O', 'U', 'U', 'U', 'U', 'Y', 's', 'a', 'a', 'a', 'a', 'a', 'a', 'ae', 'c', 'e', 'e', 'e', 'e', 'i', 'i', 'i', 'i', 'n', 'o', 'o', 'o', 'o', 'o', 'o', 'u', 'u', 'u', 'u', 'y', 'y', 'A', 'a', 'A', 'a', 'A', 'a', 'C', 'c', 'C', 'c', 'C', 'c', 'C', 'c', 'D', 'd', 'D', 'd', 'E', 'e', 'E', 'e', 'E', 'e', 'E', 'e', 'E', 'e', 'G', 'g', 'G', 'g', 'G', 'g', 'G', 'g', 'H', 'h', 'H', 'h', 'I', 'i', 'I', 'i', 'I', 'i', 'I', 'i', 'I', 'i', 'IJ', 'ij', 'J', 'j', 'K', 'k', 'L', 'l', 'L', 'l', 'L', 'l', 'L', 'l', 'l', 'l', 'N', 'n', 'N', 'n', 'N', 'n', 'n', 'O', 'o', 'O', 'o', 'O', 'o', 'OE', 'oe', 'R', 'r', 'R', 'r', 'R', 'r', 'S', 's', 'S', 's', 'S', 's', 'S', 's', 'T', 't', 'T', 't', 'T', 't', 'U', 'u', 'U', 'u', 'U', 'u', 'U', 'u', 'U', 'u', 'U', 'u', 'W', 'w', 'Y', 'y', 'Y', 'Z', 'z', 'Z', 'z', 'Z', 'z', 's', 'f', 'O', 'o', 'U', 'u', 'A', 'a', 'I', 'i', 'O', 'o', 'U', 'u', 'U', 'u', 'U', 'u', 'U', 'u', 'U', 'u', 'A', 'a', 'AE', 'ae', 'O', 'o'];

    return function (str) {
        var i = a.length;
        while (i--) str = str.replace(a[i], b[i]);
        return str;
    };
}());

applyStringFilter = function (idGrid, item, init, accentSensitive, component, bindLater) {
    //Permet de gérer différent type de composant, par défaut le KendoGrid
    if (typeof component === 'undefined' || !component) { component = 'kendoGrid'; }
    if (typeof idGrid === 'undefined' || !idGrid) { idGrid = 'Grid'; }

    var searchCriteria = $(item).data('search');
    var q = $(item).val();

    if (q != '')
        applyFilter(searchCriteria, q, 'contains', 'and', false, idGrid, accentSensitive, component, bindLater);
    else
        applyFilter(searchCriteria, '', 'contains', 'and', false, idGrid, accentSensitive, component, bindLater);
    if (!init) saveFilter($(item).attr('data-search'), "search", q);
}


applySplitStringFilter = function (idGrid, item, init, accentSensitive, component, bindLater, filterAnd) {
    var conditionFilter = 'or';
    if (typeof filterAnd !== 'undefined')
        conditionFilter = filterAnd ? 'and' : conditionFilter;
    subfilter = { logic: conditionFilter, filters: [] };

    //Permet de gérer différent type de composant, par défaut le KendoGrid
    if (typeof component === 'undefined' || !component) { component = 'kendoGrid'; }
    if (typeof idGrid === 'undefined' || !idGrid) { idGrid = 'Grid'; }

    var searchCriteria = $(item).data('search');
    var q = $(item).val();
    if (accentSensitive && typeof q !== 'undefined') {
        q = convertirChaineSansAccent(q);
    }
    var qArray = q.split(' ');
    $.each(qArray, function (index, value) {
        subfilter.filters.push({ field: searchCriteria, operator: 'contains', value: value });
    });

    toggleSubFilter2(searchCriteria, idGrid, component);

    if (!init) saveFilter($(item).attr('data-search'), "search", q);
}


applyStringFilterSch = function (item, bindLater) {
    var searchCriteria = $(item).data('search');
    var q = $(item).val();
    if (q != '')
        applyFilter(searchCriteria, q, 'contains', 'and', true, null, false, null, bindLater);
    else
        applyFilter(searchCriteria, '0', 'contains', 'and', true, null, false, null, bindLater);
    saveFilter($(item).attr('data-search'), "search", q);
}

applyStringFilterSchRes = function (item) {
    var searchCriteria = $(item).data('search');
    var q = $(item).val();
    if (q != '')
        applyFilterRes(searchCriteria, q, 'contains', 'and', true);
    else
        applyFilterRes(searchCriteria, '0', 'contains', 'and', true);
    saveFilter($(item).attr('data-search'), "search", q);
}

applyNumericFilter = function (item) {
    var searchCriteria = $(item).data('search');
    var q = $(item).val();
    if (q != '')
        applyFilter(searchCriteria, q, 'eq', 'and', false);
    else
        applyFilter(searchCriteria, '0', 'eq', 'and', false);
    saveFilter($(item).attr('data-search'), "search", q);
}

applyDateFilter = function (item, filterType, GridId, kendoGrid) { 
    var searchCriteria = $(item).data('search');
    var q = $(item).val();

    if (typeof component === 'undefined') { component = 'kendoGrid'; }
    if (typeof GridId === 'undefined') { GridId = 'Grid'; }

    if (q != '')
        applyFilter(searchCriteria, formatDate(q), filterType, 'and', false, GridId, kendoGrid);
    else
        applyFilter(searchCriteria, '0', filterType, 'and', false, GridId, kendoGrid);
    saveFilter($(item).attr('data-search'), "search", q);
}

applyCheckFilterSpecial = function (searchCriteria, value) {
    subfilter.filters.push({ field: searchCriteria, operator: 'eq', value: value });
    toggleSubFilter(searchCriteria);
}


applyMultiFilterSch = function (item, field, values) {
    subfilter = { logic: 'or', filters: [] };
    if (values != null) {
        var valData = values.toString();
        var valNew = valData.split(',');
        for (var i = 0; i < valNew.length; i++) {
            subfilter.filters.push({ field: field, operator: 'contains', value: "" + valNew[i] + "" });
        }
    }
    toggleSubFilterSchedule(field);
}

applyMultiFilterSchRes = function (item, field, values) {
    subfilter = { logic: 'or', filters: [] };
    if (values != null) {
        var valData = values.toString();
        var valNew = valData.split(',');
        for (var i = 0; i < valNew.length; i++) {
            subfilter.filters.push({ field: field, operator: 'contains', value: "" + valNew[i] + "" });
        }
    }
    toggleSubFilterScheduleRes(field);
}


applyMultipleFilter = function (filterField, filterValues, filterOperator, logic) {
    var gridData = $("#Grid").data("kendoGrid");
    var currFilterObj = gridData.dataSource.filter();
    var currentFilters;

    currentFilters = currFilterObj ? currFilterObj.filters : [];
    if (currentFilters && currentFilters.length > 0) {
        for (var i = 0; i < currentFilters.length; i++) {
            if (currentFilters[i].field == filterField) {
                currentFilters.splice(i, 1);
                i--;
            }
        }
    }
    for (var i = 0; i < filterValues.length; i++) {
        if (filterValues[i] != "0") {
            currentFilters.push({ field: filterField, operator: filterOperator, value: filterValues[i] });
        }
    }
    gridData.dataSource.filter({ logic: logic, filters: currentFilters });
}

applyMultiFilter = function (item, field, values, bindLater, idGrid, forceZero) {

    subfilter = { logic: 'or', filters: [] };
    if (values != null) {
        var valData = values.toString();
        var valNew = valData.split(',');
        if (valNew != '0' || forceZero) {
            for (var i = 0; i < valNew.length; i++) {
                subfilter.filters.push({ field: field, operator: 'IsEqualTo', value: "" + valNew[i] + "" });
            }
        }
    }
    toggleSubFilter(field, idGrid, null, bindLater);
}



applyMultiFilterOp = function (tabFilters) {

    var gridData = $('#Grid').data('kendoGrid');
    var currFilterObj = gridData.dataSource.filter();
    var currentFilters;
    currentFilters = currFilterObj ? currFilterObj.filters : [];


    if (tabFilters != null) {
        for (var i in tabFilters) {
            var tabToSuppr = new Array();
            $.each(currentFilters, function (index, item) {
                if (currentFilters[index].field == tabFilters[i].field)
                    tabToSuppr.push(index);
            });
            for (var j in tabToSuppr) {
                currentFilters.splice(tabToSuppr[j], 1);
            }
            currentFilters.push({ field: tabFilters[i].field, operator: tabFilters[i].operator, value: tabFilters[i].value });
        }
    }

    gridData.dataSource.filter({ logic: 'and', filters: currentFilters });

}


applyCheckFilter = function (item, GridId, component, bindLater, init) {

    //Permet de gérer différent type de composant, par défaut le KendoGrid
    if (typeof component === 'undefined') { component = 'kendoGrid'; }
    if (typeof GridId === 'undefined') { GridId = 'Grid'; }

    var searchCriteria = $(item).data('search');

    saveFilter($(item).attr('data-search'), "check", $(item).is(':checked'), $(item).val(), init);

    var values = $(item).val().split('|');
    if ($(item).is(':checked')) {
        $.each(values, function (index, value) {
            subfilter.filters.push({ field: searchCriteria, operator: 'eq', value: value });
        });
    }
    else {
        $.each(values, function (index, value) {
            for (var i = 0; i < subfilter.filters.length; i++) {
                if (subfilter.filters[i].value == value) {
                    subfilter.filters.splice(i, 1);
                    break;
                }
            }
        });
    }

    if ($('#Grid').data('kendoGrid') != undefined) {
        toggleSubFilter(searchCriteria, GridId, component, bindLater);
    }
}

applyFilterCustom = function (filterField, filterValue, filterOperator, logic) {
    var gridData = $('#Grid').data('kendoGrid');
    var currFilterObj = gridData.dataSource.filter();
    var currentFilters;

    currentFilters = currFilterObj ? currFilterObj.filters : [];
    if (currentFilters && currentFilters.length > 0) {
        for (var i = 0; i < currentFilters.length; i++) {
            if (currentFilters[i].field == filterField) {
                currentFilters.splice(i, 1);
                break;
            }
        }
    }
    i++;

    var format = '';
    if (filterField.indexOf('date') > 1)
        format = '{0:dd/MM/yyyy}';

    if (filterValue != '0')
        currentFilters.push({ field: filterField, operator: filterOperator, value: filterValue });

    gridData.dataSource.filter({ logic: logic, filters: currentFilters });
}

applyDropDownListFilter = function (item, init, bindLater, GridId) {
    var searchCriteria = $(item).data('search');
    var q = $(item).val();

    if (typeof GridId === 'undefined' || !GridId || GridId == "") { GridId = 'Grid'; }
    if ($('#' + GridId).data('kendoGrid') != undefined) {
        applyFilter(searchCriteria, q, 'eq', 'and', false, GridId, false, null, bindLater);
        // Code changé car les enums commencent a 0
        //if (q != '')
        //    applyFilter(searchCriteria, q, 'eq', 'and', false, GridId, false, null, bindLater);
        //else
        //    applyFilter(searchCriteria, '0', 'eq', 'and', false, GridId, false, null, bindLater);
    }

    saveFilter($(item).attr("data-search"), "kendoDropDownList", q);
}

applyDropDownListFilterSch = function (item, init) {
    var searchCriteria = $(item).data('search');
    var q = $(item).val();
    if (q != '')
        applyFilter(searchCriteria, q, 'contains', 'and', true);
    else
        applyFilter(searchCriteria, '0', 'contains', 'and', true);
    if (!init) saveFilter($(item).attr("data-search"), "kendoDropDownList_sch", q);
}

applyDropDownListFilterId = function (item, init, bindLater, idGrid) {
    var searchCriteria = $(item).data('search');
    var q = 0;
    if ($(item).val() != '') {
        q = parseInt($(item).val());

        if (q !== '')
            applyFilter(searchCriteria, q, 'eq', 'and', false, idGrid, false, null, bindLater);
    } else {
        applyFilter(searchCriteria, 0, 'eq', 'and', false, idGrid, false, null, bindLater);
    }
    if (!init) saveFilter($(item).attr("data-search"), "kendoDropDownList_id", q);
}

applyDropDownListFilterIdSch = function (item, init) {

    var searchCriteria = $(item).data('search');
    var q = 0;
    if ($(item).val() != '') {
        q = parseInt($(item).val());

        if (q !== '' && q !== 0)
            applyFilter(searchCriteria, q, 'eq', 'and', true);
    } else {
        applyFilter(searchCriteria, 0, 'eq', 'and', true);
    }
    if (!init) saveFilter($(item).attr("data-search"), "kendoDropDownList_id_sch", q);
}

applyMultiFilterId = function (item, init, bindLater, idGrid) {

    var searchCriteria = $(item).data('search');

    applyMultiFilter(item, searchCriteria, $(item).val(), bindLater, idGrid, true);
    var tab = $(item).val();
    if (!init) saveFilter($(item).attr("data-search"), "kendoMultiSelect", tab);
}

applyMultiFilterIdSch = function (item, init) {

    var searchCriteria = $(item).data('search');
    applyMultiFilterSch(item, searchCriteria, $(item).val());

    if (!init) saveFilter($(item).attr("data-search"), "kendoMultiSelect_sch", $(item).val());
}



applyMultiFilterIdRes = function (item) {
    var searchCriteria = $(item).data('search');
    applyMultiFilterSchRes(item, searchCriteria, $(item).val());
}

isDate = function (value) {
    var date_regex = /^(0[1-9]|1\d|2\d|3[01])\/(0[1-9]|1[0-2])\/(19|20)\d{2}$/;
    return date_regex.test(value);
}

formatDate = function (value) {
    var dateItem = value.split('/');
    return dateItem[1] + '/' + dateItem[0] + '/' + dateItem[2];
}



function convertirChaineSansAccent(value) {

    if (typeof (value) === 'string') {
        var accent = [
          /[\300-\306]/g, /[\340-\346]/g, // A, a
          /[\310-\313]/g, /[\350-\353]/g, // E, e
          /[\314-\317]/g, /[\354-\357]/g, // I, i
          /[\322-\330]/g, /[\362-\370]/g, // O, o
          /[\331-\334]/g, /[\371-\374]/g, // U, u
          /[\321]/g, /[\361]/g, // N, n
          /[\307]/g, /[\347]/g, // C, c
        ];
        var noaccent = ['A', 'a', 'E', 'e', 'I', 'i', 'O', 'o', 'U', 'u', 'N', 'n', 'C', 'c'];

        for (var i = 0; i < accent.length; i++) {
            value = value.replace(accent[i], noaccent[i]);
        }
    }
    return value;
}